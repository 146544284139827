import React from "react";
import {  MDBMask,MDBView,MDBRow, MDBCol, MDBCard, MDBAvatar, MDBCardBody, MDBIcon, MDBBtn } from "mdbreact";

const TeamPage = () => {
  return (

    <div class="container my-5">


  <section class="team-section text-center dark-grey-text">

   
    
  

  </section>
        <MDBRow>
          <MDBCol lg="5">
           
            <div class="row text-center">

 
              <div class="col-md-4 mb-4">
                  <div class="avatar mx-auto">
                      <img src="./assets/images/team/ion.jpg" class="rounded z-depth-1-half" alt="avatar"></img>
                  </div>
                      <h4 class="font-weight-bold dark-grey-text my-4">Ion Cojocaru</h4>
                      <h6 class="text-uppercase grey-text mb-3"><strong>Founder & CEO</strong></h6>
                    </div>
              </div>
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            
          </MDBCol>
          <MDBCol lg="7">
            <a href="#!" className="green-text">
              <h6 className="font-weight-bold mb-3">
              </h6>
            </a>
            <h3 className="h2-responsive font-weight-bold text-left my-4 mb-3 p-0">
              <strong>About Quantumsoft</strong>
            </h3>
            <p>
            Quantum soft is an ICT company with most of its employees in Romania. As you know, Romania wasn't built in a day: our people are highly trained, skilled & experienced. CEO Ion lives in Belgium and leads the team from Belgium. So what you see is only the tip of what you get. In fact, you have a full 'ICT team as a service' ... at your service.
            </p>
           
          </MDBCol>
        </MDBRow>

  


</div>



       

  );
}

export default TeamPage;