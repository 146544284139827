import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from 'react-hook-form';
import isEmail from "validator/lib/isEmail";
import axios from 'axios';
import Recaptcha from 'react-recaptcha';



 function ContactForm() {

   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [subject, setSubject] = useState('');
   const [message, setMessage] = useState('');

    async function submitForm(e){

        e.preventDefault();
        const webHOOKurl = 'https://hooks.slack.com/services/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';
        const data = {
            "text": `NAME: ${name}\n EMAIL:${email}\n SUBJECT:${subject}\n MESSAGE:${message}`
        }

        let res = await axios.post(webHOOKurl, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
                delete headers.post["Content-Type"]
                return data;
            }]
        })

        if(res.status === 200) {
            alert("Message Sent!")

            // clear state after submission
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
        }else {
            alert("Error sending message, Please try again later!")
        }

            
   }

   

  const { register, handleSubmit, errors} = useForm({
    mode: "onBlur",
  });
 
  



  return (
    <div className="contact-form--1">
                <div className="container">
                    <div className="row row--34 align-items-start">
                        <div id="contactForm" className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="h2-responsive font-weight-bold text-left my-4 mb-3 p-0">Contact Us.</h2>
                                <p className="description">Kickstart your Quantum journey.</p>
                            </div>
                            <div className="form-wrapper">
                                <form>
                                    <label htmlFor="name">

                                    {errors.name && errors.name.type === "required" && (<p>This is required</p>)}
                                    {errors.name && errors.name.type === "minLength" && (<p>This field required a min length of 6 </p>)}
                                    {errors.name && errors.name.type === "maxLength" && (<p>This field required a max length of 40 </p>)}
                                   

                                    <input 
                                    
                                    name="name" 
                                    placeholder="Your full name" 
                                    ref={register({required: true, minLength: 6, maxLength: 40, pattern: /^[A-Za-z]+$/i })}
                                    style={{ borderColor: errors.name && "red" }}
                                    value={name}
                                    onChange={e => {
                                        
                                        setName(e.target.value);
                                    
                                    }}
                       
                                    
                                    
                                    />
                                    </label>

                                    <label htmlFor="email">

                                    {errors.email && errors.email.type === "required" && (<p>This is required</p>)}
                                    {errors.email && errors.email.type === "minLength" && (<p>This field required a min length of 5 </p>)}
                                 

                                    <input 
                                    
                                    name="email" 
                                    placeholder="Your email address" 
                                    ref={register({required: true, validate: (input) => isEmail(input), })}
                                    style={{ borderColor: errors.email && "red" }}
                                    value={email}
                                    onChange={e => {
                                        
                                        setEmail(e.target.value);
                                    
                                    }}
                                    
                                    />
                                    </label>

                                    <label htmlFor="subject">

                                    {errors.subject && errors.subject.type === "required" && (<p>This is required</p>)}
                                    {errors.subject && errors.subject.type === "minLength" && (<p>This field required a min length of 10 </p>)}

                                        <input 

                                        name="subject" 
                                        placeholder="Subject" 
                                        ref={register({required: true, minLength: 10})}
                                        style={{ borderColor: errors.subject && "red" }}
                                        value={subject}
                                        onChange={e => {
                                        
                                        setSubject(e.target.value);
                                    
                                    }}

                                        />
                                    </label>
                                    <label htmlFor="message">

                                    {errors.message && errors.message.type === "required" && (<p>This is required</p>)}

                                        <textarea 
                                        
                                        name="message" 
                                        placeholder="Your message" 
                                        ref={register({ required: true, minLength: 120})}
                                        style={{ borderColor: errors.message && "red" }}
                                        value={message}
                                        onChange={e => {
                                        
                                        setMessage(e.target.value);
                                    
                                    }}

                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe"
                                        onClick={(e) => submitForm(e)}
                                    >Submit</button>
                                </form>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
  );
}
    
    

    class Contact extends Component{
        

        render() {
    return <ContactForm />;
  }
}
export default Contact;

const rootElement = document.getElementById('root');
ReactDOM.render(<Contact />, rootElement);