import React, { Component , Fragment } from "react";

import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import ServiceListTwo from "../elements/service/ServiceListTwo";
import BlogContent from "../elements/blog/BlogContent";
import FooterQ from "../component/footer/FooterQ";
import ContactQ from "../elements/contact/ContactQ";
import Helmet from "../component/common/Helmet";
import Pillars from "../elements/pillars/Pillars";
import {  MDBAvatar, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";
import TeamPage from "../elements/team/TeamPage";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title2: '',
    }
]


class HomeParticles extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        this.state = {
            isVerified: false
        }
      

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }

    handleSubmit(){
        if (this.state.isVerified){
            alert('You have succesfully subscribed!');
        }else{
            alert('Please verify that you are human!')
        }
    }


    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="Quantumsoft" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/qs_logo.png" alt="Quantumsoft"/>
                                    <img className="logo-2" src="/assets/images/logo/qs_logo.png" alt="Quantumsoft"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','service', 'about','team', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#service">Service</a></li>
                                    <li><a href="#about">About us</a></li>
                                </Scrollspy>
                            </nav>
                            <div className="header-btn">
                                <a className="rn-btn" href="#contact">
                                    <span>contact us</span>
                                </a>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                
                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="bg_image-1">
                    
                    </div>
                    <div className="bg_image bg_image--1">
                            {SlideList.map((value , index) => (
                                <div className="slide slide-style-2  d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title2 ? <h3 className="">{value.title2}</h3>: ''}
                                                    {value.description ? <p className="d">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className=""><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
         
                {/* End Slider Area   */}
           

                {/* Start Second text Area   */}

                
                {/* En Second Text Area   */}
                                   
                {/* Start Service Area  */}
                
                <div id="SecondTitel" className="my-1 px-5 pb-5 pt-5 bg_color-- my- py- z-depth-1 black-text text-center"   id="vulcano">

                <MDBRow class="bg-light">
                    <MDBCol lg="5">
                        <MDBView className="rounded z-depth-2 mb-lg-0 mb-4"  hover waves>
                            <img
                                className="img-fluid"
                                src="./assets/images/vulcano.jpg"
                                alt=""
                                id="vulcano"
               
                            />
                            <a href="#!">
                                <MDBMask overlay="white-slight" />
                            </a>
                        </MDBView>
                    </MDBCol>
        
                    <MDBCol lg="7">
                        <a href="#!" className="green-text">
                            <h6 className="font-weight-bold mb-3">
                            </h6>
                        </a>

                        <h3 className="white-text pt-10">
                            Throw € 100K into a volcano & you will get little return on investment. Still, every year millions are spent on digital transformation with only 
                            this result: a meeting room wall covered in sticky notes & a puzzling 100 page ppt-presentation. (If you pay extra, you get animated arrows.)
                        </h3>
          
                    </MDBCol>
                </MDBRow>
             </div>       
                {/* End Service Area  */} 

                {/* Start Main Secret Area  */} 

                <div id="secretText"class="container my-5 p-5 z-depth-0">               
                <section class="text-left dark-grey-text">
                  <h2 className="h2-responsive font-weight-bold text-left my-10 mb-5 p-0">Our main secret is: Thinking ahead</h2>
                  <div class="row">
                    <div class="col-md-3 mb-4">
              
                      <i class="fas fa-drafting-compass fa-3x pink-text"></i>
                      <h3 class="font-weight-bold my-4">Very well prepared</h3>
                      <p class="grey-text mb-md-0">A successful project without nasty 
                      surprises starts with solid planning and good governance.
                      </p>
              
                    </div>
                   
                    <div class="col-md-3 mb-4">
              
                      <i class="fas fa-award fa-3x pink-text "></i>
                      <h3 class="font-weight-bold my-4">Impeccable execution</h3>
                      <p class="grey-text mb-md-0">We strive for high-quality standards throughout 
                      the project lifecycle: no sloppy work or volcano accidents!
                      </p>
              
                    </div>
                  
                    <div class="col-md-3 mb-4">
              
                      <i class="fas fa-toolbox fa-3x pink-text"></i>
                      <h3 class="font-weight-bold my-4">Carefully maintained</h3>
                      <p class="grey-text mb-0">Quantumsoft is a trusted partner that maintains your digital solution. 
                      We manage your environment while you focus on the business.
                      </p>
              
                    </div>

                    <div class="col-md-3 mb-4">
                      <i class="fas fa-shield-alt fa-3x pink-text"></i>
                      <h3 class="font-weight-bold my-4">Firmly secured</h3>
                      <p class="grey-text mb-0">ICT security is in high demand as hackers across the world try to target the weakest. 
                      Quantumsoft will secure your application. 
                      </p>

                    </div>
                  </div>
                </section>
              </div>

                    
                {/* End Main Secret Area  */} 


                {/* Start About Area */}
                <Pillars></Pillars>
                <div className="about-area ptb--30  bg_color--3" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                        <TeamPage></TeamPage>
                        </div>
                    </div>
                </div>
                
    
                {/* End About Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <ContactQ />
                </div>
                {/* End Contact Us */}


                 {/* Start Footer Style  */}
                 <FooterQ />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;