const BlogContent = [
    {
        images: '01',
        title: ' ',
        category: 'Development'
    },
    {
        images: '02',
        title: ' ',
        category: 'Management'
    },

    {
        images: '03',
        title: '',
        category: 'Design'
    },
    {
        images: '04',
        title: ' ',
        category: 'Development'
    },
    {
        images: '01',
        title: '',
        category: 'Management'
    },
    {
        images: '02',
        title: '',
        category: 'Design'
    },
]

export default BlogContent;