import React ,{ Component }from "react";
import { FiCheckSquare,FiAward, FiSettings,FiShield } from "react-icons/fi";
import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";

const ServiceList = [
    {
        icon: < FiCheckSquare/>,
        title: 'Very well prepared',
        description: 'A successful project without nasty surprises starts with solid planning and good governance.'
    },
    {
        icon: <FiAward />,
        title: 'Impeccable execution',
        description: 'We strive for high-quality standards throughout the project lifecycle: no sloppy work or volcano accidents!'
    },
    {
        icon: <FiSettings/>,
        title: 'Carefully maintained',
        description: 'Quantum Soft is a trusted partner that maintains your digital solution. We manage your environment while you focus on the business.'
    },
    { 
        icon: <FiShield />,
        title: 'Firmly secured',
        description: 'ICT security is in high demand as hackers across the world try to target the weakest. Quantum soft will secure your application. '
    },
   
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <MDBCard className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </MDBCard>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
