import React ,{ Component }from "react";


const ServiceList = [
    {
        
        title: 'Quantum Jump Start',
        description: 'Let&#39; s meet & set up an ICT Architectural Model, assessing feasibility & technology fit. We will finetune the planning, ideate the concept and define delivery requirements.',
        subtext: 'NICE TO KNOW: We are stack agnostic.  Meaning we don&#39;t stick to one technology or stack. We rather stick to what you need.'
    },
    {
   
        title: 'Quantum Leap',
        description: 'The actual development in cycles of two weekly agile sprints. After an in-depth kick-off we pick up speed by focusing on interim goals, creating one efficient flow until delivery.',
        subtext: 'NICE TO KNOW: at the end of every sprint  cycle, a demo is provided to showcase the progress. Keeps the energy flowing.'
        
    },
    {
    
        title: 'Quantum Maintenance',
        description: 'Many transformations end up dead because of - well, you know - they just die. Quantum Soft manages the whole process & we also caringly maintain your new digital infrastructure.',
        subtext: 'NICE TO KNOW:maintenance is where  ICT cowboys don&#39;t go. We consider &#39;all  systems working’ essential to our craft.'
    },
    { 
       
        title: 'Quantum Safety Audit',
        description: 'You dont want to pay ransome to get access to your own digital platform. Thousands of companies are attacked every day. We will audit your security & fix the holes if needed.',
        subtext: 'NICE TO KNOW: afterwards we will monitor  the security measures from our audit to  be sure they are sufficient and effective.'
        

    },
   
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                        <p>{val.subtext}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
