import React from "react";
import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdbreact";

const Pillars = () => {
  return (
    <div className="my-0 px-5 pb-0">
    <div class="container my-0 z-depth-0">
      <MDBCardBody id="service">
        <h1 className="h2-responsive font-weight-bold text-left my-5">
        Our 4 service pillars: 
        </h1>
        <MDBRow class="bg-light">
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
              <picture>
                <source type="image/webp" srcset="./assets/images/pillars/pillar1.webp"/>
                <source type="image/jpeg" srcset="./assets/images/pillars/pillar1.jpg"/>
                <img
                className="img-fluid"
                src="./assets/images/pillars/pillar1.jpg"
                alt=""
              />
              </picture>
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
        
          <MDBCol lg="7">
            <a href="#!" className="green-text">
              <h6 className="font-weight mb-3">
              </h6>
            </a>
            <h2 className="h2-responsive font-weight-bold text-left my-4 mb-3 p-0">
              <strong>Quantum Jump Start</strong>
            </h2>
            <h4>
            Let's meet & set up an ICT Architectural Model, assessing feasibility & technology fit. 
            We will finetune the planning, ideate the concept and define delivery requirements.
            </h4>
            
              <p className="pink-text">
                <strong>NICE TO KNOW: </strong>
              </p>
              <p className="niceToKnow">
              We are stack agnostic.  Meaning we don't stick to one technology or stack. 
              We rather stick to what you need.
            </p>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />

        <MDBRow class="bg-light">
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
              <picture>
                <source type="image/webp" srcset="./assets/images/pillars/pillar2.webp"/>
                <source type="image/jpeg" srcset="./assets/images/pillars/pillar2.jpg"/>
              <img
                className="img-fluid"
                src="./assets/images/pillars/pillar2.jpg"
                alt=""
              />
              </picture>
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7">
            <a href="#!" className="green-text">
              <h6 className="font-weight mb-3">
              </h6>
            </a>
            <h2 className="h2-responsive font-weight-bold text-left my-4 mb-3 p-0">
              <strong>Quantum Leap</strong>
            </h2>
            <h4>
            The actual development in cycles of two weekly agile sprints. After an in-depth 
            kick-off we pick up speed by focusing on interim goals, creating one efficient 
            flow until delivery.
            </h4>
            <p className="niceToKnow">
              <p className="pink-text">
                <strong>NICE TO KNOW: </strong>
              </p>
              At the end of every sprint  cycle, a demo is provided to showcase the progress. 
              Keeps the energy flowing.
            </p>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />

        <MDBRow class="bg-light">
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
              <picture>
                <source type="image/webp" srcset="./assets/images/pillars/pillar3.webp"/>
                <source type="image/jpeg" srcset="./assets/images/pillars/pillar3.jpg"/>

              <img
                className="img-fluid"
                src="./assets/images/pillars/pillar3.jpg"
                alt=""
              />
              </picture>
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7">
            <a href="#!" className="indigo-text">
              <h6 className="font-weight-bold mb-3">
              </h6>
            </a>
            <h2 className="h2-responsive font-weight-bold text-left my-4 mb-3 p-0">
              <strong>Quantum Maintenance</strong>
            </h2>
            <h4>
            Many transformations end up dead because of - well, you know - they just die. 
            Quantum Soft manages the whole process & we also caringly maintain your new digital infrastructure.
            </h4>
            <p className="niceToKnow">
              <p className="pink-text">
                <strong>NICE TO KNOW: </strong>
              </p>
              Maintenance is where  ICT cowboys don't go. 
              We consider 'all  systems working’ essential to our craft. 
            </p>
          </MDBCol>
          </MDBRow>
        

        <hr className="my-5" />


        <MDBRow class="bg-light">
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
              <picture>
                <source type="image/webp" srcset="./assets/images/pillars/pillar4.webp"/>
                <source type="image/jpeg" srcset="./assets/images/pillars/pillar4.jpg"/>
              <img
                className="img-fluid"
                src="./assets/images/pillars/pillar4.jpg"
                alt=""
              />
              </picture>
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7">
            <a href="#!" className="indigo-text">
              <h6 className="font-weight-bold mb-3">
              </h6>
            </a>
            <h2 className="h2-responsive font-weight-bold text-left my-4 mb-3 p-0">
              <strong>Quantum Safety Audit</strong>
            </h2>
            <h4 >
            You don't want to pay ransome to get access to your own digital platform. 
            Thousands of companies are attacked every day. We will audit your security & fix the holes if needed.
            </h4>
            <p className="niceToKnow">
              <p className="pink-text">
                <strong>NICE TO KNOW: </strong>
              </p>
              Afterwards we will monitor  the security measures from our audit to  be sure they are sufficient and effective. 
            </p>
          </MDBCol>
          </MDBRow>
        

        <hr className="my-5" />
        
      </MDBCardBody>
      </div>
    </div>
  );
}

export default Pillars;